<template>
  <b-row>
    <b-col>
      <!-- Modals -->
      <div>
        <!-- Delete Modal -->
        <b-modal
          id="delete_modal"
          title="Confirm"
          size="sm"
          no-close-on-backdrop
          no-close-on-esc
          hide-footer
        >
          <div class="text-center">
            <h1>Are you sure?</h1>
            <p>
              You are about to delete the schedule item:<br />
              <strong>"{{ delete_item.name }}"</strong>
            </p>
            <base-button
              type="danger"
              :pill="true"
              class="my-4 btn-success"
              @click="delete_schedule_item()"
              >Delete
            </base-button>
            <base-button
              type="primary"
              :pill="true"
              class="my-4 btn-success"
              @click="$bvModal.hide('delete_modal')"
              >Cancel
            </base-button>
          </div>
        </b-modal>
        <!-- CRU Modal -->
        <b-modal
          id="cru_modal"
          :title="
            cru_modal.mode == 'create'
              ? 'Create schedule event'
              : 'Edit schedule event'
          "
          size="md"
          no-close-on-backdrop
          no-close-on-esc
          hide-footer
        >
          <div>
            <b-row>
              <b-col>
                <validation-observer
                  v-slot="{ handleSubmit }"
                  ref="formValidator"
                >
                  <b-form
                    role="form"
                    @submit.prevent="handleSubmit(submit_form)"
                  >
                    <input type="submit" ref="form_submit_button" hidden />
                    <b-row>
                      <b-col>
                        <base-input
                          :pill="true"
                          label="Name*"
                          class="mb-3"
                          name="Name"
                          :rules="{ required: true }"
                          placeholder="Name of the schedule event"
                          v-model="cru_modal.form.name"
                          :disabled="cru_modal.loading"
                        >
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        ><html-editor
                          :pill="true"
                          label="Schedule event detail description"
                          class="mb-3"
                          name="Detail Description"
                          v-model="cru_modal.form.description"
                          :disabled="cru_modal.loading"
                        >
                        </html-editor
                      ></b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="12" md="6">
                        <base-input
                          :pill="true"
                          type="datetime-local"
                          id="start-datetime"
                          label="Start date and time"
                          v-model="cru_modal.form.start_time"
                          :rules="{ required: true }"
                          :disabled="cru_modal.loading"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="12" md="6">
                        <base-input
                          :pill="true"
                          type="datetime-local"
                          id="end-datetime"
                          label="End date and time"
                          v-model="cru_modal.form.end_time"
                          :rules="{ required: true }"
                          :disabled="cru_modal.loading"
                        ></base-input>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="text-right">
                <base-button
                  type="danger"
                  :pill="true"
                  class="my-4 btn-success"
                  @click="$bvModal.hide('cru_modal')"
                  :disabled="cru_modal.loading"
                  >Cancel
                </base-button>
                <base-button
                  type="success"
                  :pill="true"
                  class="my-4 btn-success"
                  @click="save_cru_modal()"
                  :disabled="cru_modal.loading"
                  :loading="cru_modal.loading"
                  :success="cru_modal.success"
                  >Save
                </base-button>
              </b-col>
            </b-row>
          </div>
        </b-modal>
      </div>
      <b-card>
        <b-card-body>
          <b-row>
            <b-col cols="6">
              <h1>Hackathon Schedule</h1>
            </b-col>
            <b-col cols="6" class="text-right">
              <el-tooltip
                content="Add"
                placement="top"
                v-if="is_hackathon_creator"
              >
                <base-button
                  type="primary"
                  round
                  icon
                  size="sm"
                  @click="show_cru_modal()"
                  v-if="is_hackathon_creator || is_hackathon_manager"
                >
                  <span class="btn-inner"><i class="fa fa-plus"></i></span>
                </base-button>
              </el-tooltip>
            </b-col>
          </b-row>

          <div class="timeline">
            <time-line type="one-side">
              <time-line-item
                v-for="(item, index) in schedule_items"
                :key="index"
                badge-type="primary"
                dot-only
              >
                <b-row>
                  <b-col sm="auto" class="d-flex align-items-center justify-content-start">
                    <!-- Timeline Dot -->
                    <div class="timeline-dot"></div>
                  </b-col>
                  <b-col>
                    <b-row class="align-items-center">
                      <b-col sm="auto">
                        <h4 class="mt--1 mb-0">{{ item.name }}</h4>
                      </b-col>
                      <b-col
                        v-if="is_hackathon_creator || is_hackathon_manager"
                        class="text-right d-flex align-items-center justify-content-end"
                        sm="auto"
                      >
                        <base-button
                          type="primary"
                          size="sm"
                          class="edit-button"
                          @click="show_cru_modal(item)"
                        >
                          Edit
                        </base-button>
                        <base-button
                          type="danger"
                          size="sm"
                          class="ml-2 delete-button" 
                          @click="show_delete_modal(item)"
                        >
                          <i class="fas fa-trash"></i>
                        </base-button>
                      </b-col>
                    </b-row>
                    <p>{{ get_date_sting(item.startTime) }} - {{ get_date_sting(item.endTime) }}</p>
                    <p v-html="item.description"></p>
                  </b-col>
                </b-row>
              </time-line-item>
            </time-line>
          </div>

        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.timeline {
  position: relative;
  padding: 20px 0;
}

.edit-button, .delete-button {
  padding: 4px 8px; /* Adjust padding as needed */
  font-size: 14px;  /* Adjust font size as needed */
}

.ml-2 {
  margin-left: 0.5rem; /* Adjust spacing between buttons */
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.time-line-item {
  position: relative;
  padding-left: 40px; /* Adjust as necessary */
  margin-bottom: 40px; /* Space between items */
}

.time-line-item:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left:50%;
  margin-left: -481px; /* Position this to match the center of the timeline-dot */
  width: 2px;
  background: rgb(255, 174, 0); /* The color of the timeline line */
  z-index:1;
}

.timeline-dot {
  width: 12px;
  height: 12px;
  background-color: #f3bddd;
  border-radius: 50%;
  position: absolute;
  left: 10px ;/* Adjust to align with the line */
  top: 0;
  z-index:2;
}

.time-line-item h4 {
  margin-bottom: 10px;
  padding-left: 40px; /* Adjust this to position content to the right of the line */
  position: relative;
  font-size: 24px; /* Increased font size for headings */
  font-weight: bold; /* Optionally make the heading bold */
}

.time-line-item p {
  margin-bottom: 10px;
  margin-left: 40px; /* Indent the description text */
  font-size: 16px; /* Consistent font size for paragraphs */
  line-height: 1.5;
}

.icon-button {
  background-color: #f5f5f5; /* Light background color */
  border: none;
  border-radius: 4px;
  padding: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px; /* Added margin to space buttons out */
}

@media (min-width: 992px) {
  .timeline::before {
    left: 50%;
    margin-left: -480px;
  }
}

.icon-button i {
  color: #283593; /* Dark blue color */
  font-size: 16px;
}

.additional-info {
  margin-left: 40px;
  margin-top: 10px;
  font-size: 14px;
  color: #555;
}

.strike {
  text-decoration: line-through;
}

.action-buttons {
  margin-left: 40px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>

<script>
// Components
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
} from "element-ui";
import HtmlEditor from "@/components/Inputs/HtmlEditor";

// Queries
import { GET_HACKATHON_PROGRAM } from "@/graphql/queries";

// Mutations
import {
  CREATE_HACKATHON_PROGRAM,
  UPDATE_HACKATHON_PROGRAM,
  DELETE_HACKATHON_PROGRAM,
} from "@/graphql/mutations";

export default {
  name: "HackathonScheduleTable",
  components: {
    HtmlEditor,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  props: {
    hackathon_id: {
      type: String,
      description: "The id of the Hackathon",
      default: null,
    },
  },
  apollo: {
    get_hackathon_program: {
      query: GET_HACKATHON_PROGRAM,
      result(res) {
        this.schedule_items = graph_utils.flatten_objects_recursive(
          graph_utils.apollo_to_obj_recursive(
            res.data.hackathonHackathon.programItems
          )
        );

        this.schedule_items.sort(
          (a, b) => new Date(a.startTime) - new Date(b.startTime)
        );

        this.is_hackathon_creator = res.data.hackathonHackathon.isCreator;
        this.is_hackathon_manager = res.data.hackathonHackathon.isManager;
      },
      update(data) {
        this.apollo_data.get_hackathon_program = data;
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      variables() {
        return {
          hackathon_id: this.hackathon_id,
        };
      },
      skip: true,
    },
  },
  data() {
    return {
      apollo_data: {
        get_hackathon_program: null,
      },
      schedule_items: [],
      delete_item: {
        name: null,
      },
      cru_modal: {
        edit_item: {},
        mode: "create", // create or edit
        form: {
          name: null,
          description: "",
          start_time: null,
          end_time: null,
          loading: false,
        },
        loading: false,
        success: false,
      },
      is_hackathon_creator: false,
      is_hackathon_manager: false,
    };
  },

  methods: {
    // Apollo Methods
    manage_get_hackathon_program() {
      if (this.hackathon_id != null && this.hackathon_id != "null") {
        graph_utils.enable_query(this.$apollo.queries.get_hackathon_program);
      } else {
        graph_utils.disable_query(this.$apollo.queries.get_hackathon_program);
      }
    },

    get_date_sting(date_string) {
      let date = new Date(date_string);
      let today = new Date();
      let formatted_date_string = "";

      if (date.toLocaleDateString() != today.toLocaleDateString()) {
        formatted_date_string += date.toLocaleDateString() + " ";
      }
      formatted_date_string += date.toLocaleTimeString();

      return formatted_date_string;
    },
    time_has_passed(date_string) {
      let date = new Date(date_string);
      let today = new Date();
      if ((date) => today) {
        return false;
      } else {
        return true;
      }
    },

    // Delete Modal

    show_delete_modal(schedule_item) {
      this.delete_item = schedule_item;
      this.$bvModal.show("delete_modal");
    },

    // Delete the schedule item.
    delete_schedule_item() {
      this.$apollo
        .mutate({
          mutation: DELETE_HACKATHON_PROGRAM,
          variables: {
            id: this.delete_item.id,
          },
        })
        .then((res) => {
          this.$bvModal.hide("delete_modal");
          // Refetching the program data
          this.$apollo.queries.get_hackathon_program.refetch();
          // TODO: Push the user back to a particular page.
          // Perhaps the hackathon default page.
        })
        .catch((res) => {
          // TODO: Create notification that something went wrong.
        });
    },

    // CRU Modal
    show_cru_modal(schedule_item) {
      if (schedule_item) {
        // Edit Existing
        this.cru_modal.edit_item = schedule_item;
        this.cru_modal.form = {
          name: schedule_item.name,
          description: schedule_item.description,
          start_time: schedule_item.startTime.substring(0, 16),
          end_time: schedule_item.endTime.substring(0, 16),
        };

        this.cru_modal.mode = "edit";
        this.$bvModal.show("cru_modal");
      } else {
        // Create one
        this.cru_modal.edit_item = {};
        this.cru_modal.form = {
          name: null,
          description: "",
          start_time: null,
          end_time: null,
        };
        this.cru_modal.mode = "create";
        this.$bvModal.show("cru_modal");
      }
    },
    save_cru_modal() {
      this.$refs.form_submit_button.click();
    },
    submit_form() {
      if (this.cru_modal.mode == "create") {
        this.create_schedule_item();
      } else if (this.cru_modal.mode == "edit") {
        this.update_schedule_item();
      }
    },
    create_schedule_item() {
      this.cru_modal.loading = true;

      this.$apollo
        .mutate({
          mutation: CREATE_HACKATHON_PROGRAM,
          variables: {
            hackathon_id: this.hackathon_id,
            name: this.cru_modal.form.name,
            description: this.cru_modal.form.description,
            start_time: this.cru_modal.form.start_time,
            end_time: this.cru_modal.form.end_time,
          },
        })
        .then((res) => {
          this.cru_modal.loading = false;
          this.cru_modal.success = true;
          // Refetching the program data
          this.$apollo.queries.get_hackathon_program.refetch();
          setTimeout(() => {
            this.cru_modal.success = false;
            this.$bvModal.hide("cru_modal");
          }, 1000);
        })
        .catch((res) => {
          this.cru_modal.loading = false;
          // TODO: Create notification that something went wrong.
        });
    },
    update_schedule_item() {
      this.cru_modal.loading = true;

      this.$apollo
        .mutate({
          mutation: UPDATE_HACKATHON_PROGRAM,
          variables: {
            id: this.cru_modal.edit_item.id,
            name: this.cru_modal.form.name,
            description: this.cru_modal.form.description,
            start_time: this.cru_modal.form.start_time,
            end_time: this.cru_modal.form.end_time,
          },
        })
        .then((res) => {
          this.$apollo.queries.get_hackathon_program.refetch();
          this.cru_modal.loading = false;
          this.cru_modal.success = true;
          setTimeout(() => {
            this.cru_modal.success = false;
            this.$bvModal.hide("cru_modal");
          }, 1000);
        })
        .catch((res) => {
          this.cru_modal.loading = false;
          // TODO: Create notification that something went wrong.
        });
    },

    // Not working will implement if needed
    // duration_string(start_datetime, end_datetime) {
    //     let duration_ms = end_datetime - start_datetime;
    //     let days = Math.floor(duration_ms / (1000 * 60 * 60 * 24));
    //     let hours = Math.floor(
    //         (duration_ms % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    //     );
    //     let minutes = Math.floor(
    //         (duration_ms % (1000 * 60 * 60)) / (1000 * 60)
    //     );

    //     if (days > 0) {
    //         return `${days} Days`;
    //     }
    //     else if (hours> 0 ){
    //         if (minutes > 0){
    //             return `${hours} Hours`;
    //         }
    //         else{}

    //     }
    // },
  },

  mounted() {
    this.manage_get_hackathon_program();
  },
  watch: {
    hackathon_id() {
      this.manage_get_hackathon_program();
    },
  },
  created() {
    // setting this queries fetch policy
    graph_utils.set_fetch_cache_and_network(
      this.$apollo.queries.get_hackathon_program
    );
  },
};
</script>

<style>
.strikethrough {
  text-decoration: line-through;
}
/* .table-rows {
    border-bottom: red !important;
} */
</style>
