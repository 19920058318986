<template>
  <div>
    <!-- Spinner positioned using Element UI's v-loading -->
    <b-row v-loading="loading" id="loading"></b-row>

    <!-- Main Content, shown only when loading is false -->
    <div v-if="!loading">
      <b-row>
        <b-col>
          <!-- Modals -->
          <div>
            <!-- Delete Modal -->
            <b-modal id="delete_modal" title="Confirm" size="sm" no-close-on-backdrop no-close-on-esc hide-footer>
              <div class="text-center">
                <h1>Are you sure?</h1>
                <p>
                  You are about to delete the schedule item:<br />
                  <strong>"{{ delete_item.name }}"</strong>
                </p>
                <base-button type="danger" :pill="true" class="my-4 btn-success" @click="delete_schedule_item()">Delete
                </base-button>
                <base-button type="primary" :pill="true" class="my-4 btn-success"
                  @click="$bvModal.hide('delete_modal')">Cancel
                </base-button>
              </div>
            </b-modal>

            <!-- CRU Modal -->
            <b-modal id="cru_modal" :title="cru_modal.mode == 'create'
              ? 'Create schedule event'
              : 'Edit schedule event'
              " size="md" no-close-on-backdrop no-close-on-esc hide-footer>
              <div>
                <!-- Form Content -->
                <!-- Your existing form content here -->
              </div>
            </b-modal>
          </div>

          <b-card>
            <b-card-body>
              <b-row>
                <b-col cols="6" class="text-left text-md-left">
                  <h1>Program</h1>
                </b-col>
                <b-col cols="6" class="text-right d-flex align-items-center justify-content-end">
                  <el-tooltip content="Add" placement="top" v-if="is_hackathon_creator">
                    <base-button type="primary" round icon size="sm" @click="show_cru_modal()"
                      v-if="is_hackathon_creator || is_hackathon_manager">
                      <span class="btn-inner"><i class="fa fa-plus"></i></span>
                    </base-button>
                  </el-tooltip>
                </b-col>
              </b-row>

              <div class="timeline">
                <time-line type="one-side">
                  <time-line-item v-for="(item, index) in schedule_items" :key="index" badge-type="primary" dot-only>
                    <b-row>
                      <b-col sm="auto" class="d-flex align-items-center justify-content-start">
                        <!-- Timeline Dot -->
                        <div class="timeline-dot"></div>
                      </b-col>
                      <b-col>
                        <b-row>
                          <b-col cols="10">
                            <h4 class="mt--1 mb-0">{{ item.name }}</h4>
                          </b-col>
                          <b-col cols="2" v-if="is_hackathon_creator || is_hackathon_manager"
                            class="d-flex align-items-center justify-content-end" style="padding-right: 5px;">
                            <div class="d-flex align-items-center" style="min-width: 30px;">
                              <!-- Styled Edit Button -->
                              <el-tooltip content="Edit" placement="top">
                                <a href="#!" @click.prevent="show_cru_modal(item)" class="table-action"
                                  data-toggle="tooltip" data-original-title="Edit">
                                  <i class="fas fa-edit"></i>
                                </a>
                              </el-tooltip>

                              <!-- Updated Delete Button -->
                              <el-tooltip content="Delete" placement="top">
                                <a href="#!" @click.prevent="show_delete_modal(item)" class="table-action"
                                  data-toggle="tooltip" data-original-title="Delete">
                                  <i class="fas fa-trash"></i>
                                </a>
                              </el-tooltip>
                            </div>
                          </b-col>

                        </b-row>
                        <p>
                          {{ get_date_sting(item.startTime) }}
                        </p>
                        <p v-html="item.description"></p>
                      </b-col>
                    </b-row>
                  </time-line-item>
                </time-line>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>





<style>
/* Your existing styles */

.edit-button {
  background-color: transparent;

  border: none;
  /*color: #32325d; /* Customize the color as needed */
  cursor: pointer;
  font-size: 18px;
  padding: 0;
}

#loading {
  min-height: 30px;
  display: flex;
  align-items: center;
}

.el-loading-spinner .path {
  stroke: #66615b !important;
}

.full-screen-loading {
  position: relative;
  min-height: 100vh;
  /* Ensure it covers the full screen */
}

.el-loading-mask {
  background: transparent !important;
}

.edit-button i {
  font-size: 18px;
  /* Adjust icon size */
  margin: 0;
}

.edit-button:hover {
  /*color: #0056b3; /* Darker shade on hover */
}

/* Updated styles for the delete button */
.table-action {
  color: #6c757d;
  /* Initial color */
  font-size: 18px;
  /* Adjust icon size */
  cursor: pointer;
}

.table-action:hover {
  color: #dc3545;
  /* Red color on hover */
}

.ml-2 {
  margin-left: 0.5rem;
  /* Adjust spacing between buttons */
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.time-line-item {
  position: relative;
  padding-left: 40px;
  /* Adjust as necessary */
  margin-bottom: 40px;
  /* Space between items */
}

.time-line-item:before {
  content: "";
  position: absolute;
  top: 12px;
  bottom: 0;
  left: 30px;
  width: 2px;
  background: rgb(255, 174, 0);
  /* The color of the timeline line */
  z-index: 1;
}

.timeline-dot {
  width: 12px;
  height: 12px;
  background-color: #f3bddd;
  border-radius: 50%;
  position: absolute;
  left: 24px;
  /* Adjust to align with the line */
  top: 6px;
  z-index: 2;
}

.time-line-item .action-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  /* Adds space between the edit and delete buttons */
  min-width: 120px;
  /* Ensures consistent alignment by setting a minimum width */
}

.time-line-item .align-items-center {
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  /* Ensures consistent height across rows */
}

.time-line-item h4 {
  margin-bottom: 10px;
  padding-left: 20px;
  /* Adjust this to position content to the right of the line */
  position: relative;
  font-size: 24px;
  /* Increased font size for headings */
  font-weight: bold;
  /* Optionally make the heading bold */
}

.time-line-item p {
  margin-bottom: 10px;
  margin-left: 20px;
  /* Indent the description text */
  font-size: 16px;
  /* Consistent font size for paragraphs */
  line-height: 1.5;
}

.icon-button {
  background-color: #f5f5f5;
  /* Light background color */
  border: none;
  border-radius: 4px;
  padding: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  /* Added margin to space buttons out */
}

@media (min-width: 992px) {
  .timeline::before {
    left: 50%;
    margin-left: -466px;
  }
}

@media (max-width: 768px) {
  .time-line-item {
    padding-left: 20px;
  }

  .timeline-dot {
    left: 10px;
  }
}

@media (max-width: 768px) {
  .time-line-item {
    padding-left: 20px;
    /* Reduce padding for smaller screens */
    margin-bottom: 20px;
    /* Less space between items on mobile */
  }

  .time-line-item:before {
    left: 10px;
    /* Adjust the line position */
    top: 20px;
    /* Start the line closer to the top */
    bottom: 20px;
    /* End the line closer to the bottom */
  }

  .timeline-dot {
    left: 5px;
    /* Position the dot closer to the left edge */
    top: 20px;
    /* Adjust the dot position to align with the new text position */
    transform: translateY(0);
    /* Remove the vertical centering */
  }

  .time-line-item h4,
  .time-line-item p {
    padding-left: 30px;
    /* Add some padding to separate the text from the dot */
    font-size: 18px;
    /* Slightly smaller font size for mobile */
  }
}

@media (max-width: 480px) {
  .time-line-item {
    padding-left: 30px;
    /* Adjust padding to better fit the screen */
    margin-bottom: 30px;
    /* Adjust space between items */
  }

  .timeline::before {
    left: -3%;
    margin-left: 0px;
  }

  .time-line-item:before {
    left: 50px;
    /* Position the line closer to the edge */
    top: 0;
    /* Start the line from the top */
    bottom: 0;
    /* Extend the line fully */
  }

  .timeline-dot {
    left: 0px;
    /* Position the dot closer to the left edge */
    top: 3px;
    /* Align the dot with the text */
    transform: translateY(0);
    /* Remove the vertical centering */
  }

  .time-line-item h4,
  .time-line-item p {
    padding-left: 40px;
    /* Ensure enough space between the dot and text */
    font-size: 16px;
    /* Slightly reduce the font size for better fit */
  }

  .time-line-item h4 {
    line-height: 1.2;
    /* Adjust line height for better readability */
  }

  /* Ensure buttons stay aligned and tappable */
  .edit-button,
  .delete-button {
    margin-left: 10px;
    font-size: 16px;
    /* Adjust icon size */
  }
}



.icon-button i {
  color: #283593;
  /* Dark blue color */
  font-size: 16px;
}

.additional-info {
  margin-left: 40px;
  margin-top: 10px;
  font-size: 14px;
  color: #555;
}

.strike {
  text-decoration: line-through;
}

.text-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 0.5rem;
}

.base-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  /* You can adjust these values */
  height: 40px;
  /* You can adjust these values */
}

.timeline .table-action {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  /* Adjust spacing between icons */
  font-size: 18px;
  padding-top: 0.5rem;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(12, 2, 12, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}


.loading-spinner {
  text-align: center;
}


.action-buttons {
  margin-left: 40px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>

<script>
import Vue from 'vue';
import { Loading } from 'element-ui';

Vue.use(Loading.directive);

export default {
  data() {
    return {
      loading: true, // Show spinner initially
    };
  },
  mounted() {
    console.log('Loading state at mount:', this.loading);
    // Simulate data loading
    setTimeout(() => {
      this.loading = false; // Hide spinner when data is loaded
      console.log('Loading state after timeout:', this.loading);
    }, 3000); // Adjust this timing as needed
  }
};
</script>

<script>
// Components
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
} from "element-ui";
import HtmlEditor from "@/components/Inputs/HtmlEditor";

// Queries
import { GET_HACKATHON_PROGRAM } from "@/graphql/queries";

// Mutations
import {
  CREATE_HACKATHON_PROGRAM,
  UPDATE_HACKATHON_PROGRAM,
  DELETE_HACKATHON_PROGRAM,
} from "@/graphql/mutations";

export default {
  name: "HackathonScheduleTable",
  components: {
    HtmlEditor,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  props: {
    hackathon_id: {
      type: String,
      description: "The id of the Hackathon",
      default: null,
    },
  },
  apollo: {
    get_hackathon_program: {
      query: GET_HACKATHON_PROGRAM,
      result(res) {
        this.schedule_items = graph_utils.flatten_objects_recursive(
          graph_utils.apollo_to_obj_recursive(
            res.data.hackathonHackathon.programItems
          )
        );

        this.schedule_items.sort(
          (a, b) => new Date(a.startTime) - new Date(b.startTime)
        );

        this.is_hackathon_creator = res.data.hackathonHackathon.isCreator;
        this.is_hackathon_manager = res.data.hackathonHackathon.isManager;

        this.loading = false; //To hide the spinner
      },
      update(data) {
        this.apollo_data.get_hackathon_program = data;
      },
      error(errors) {
        console.log(
          "Smart Query Error Handler: " + this.$options.name
        );
        console.log(errors.graphQLErrors);
        return false;
      },
      variables() {
        return {
          hackathon_id: this.hackathon_id,
        };
      },
      skip: true,
    },
  },
  data() {
    return {
      loading: true,
      apollo_data: {
        get_hackathon_program: null,
      },
      schedule_items: [],
      delete_item: {
        name: null,
      },
      cru_modal: {
        edit_item: {},
        mode: "create", // create or edit
        form: {
          name: null,
          description: "",
          start_time: null,
          end_time: null,
          loading: false,
        },
        loading: false,
        success: false,
      },
      is_hackathon_creator: false,
      is_hackathon_manager: false,
    };
  },

  methods: {
    // Apollo Methods
    manage_get_hackathon_program() {
      if (
        this.hackathon_id != null &&
        this.hackathon_id != "null"
      ) {
        graph_utils.enable_query(
          this.$apollo.queries.get_hackathon_program
        );
      } else {
        graph_utils.disable_query(
          this.$apollo.queries.get_hackathon_program
        );
      }
    },
    get_date_sting(date_string) {
      if (!date_string) return ''; // Return an empty string if date_string is not provided

      const date = new Date(date_string);

      if (isNaN(date.getTime())) {
        return ''; // Return an empty string if the date is invalid
      }

      const formatted_date_string = date.toLocaleDateString([], {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      });

      const formatted_time_string = date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      });

      return `${formatted_date_string} ${formatted_time_string}`;
    },

    time_has_passed(date_string) {
      const date = new Date(date_string);

      if (isNaN(date.getTime())) {
        return false; // Assume the time has not passed if the date is invalid
      }

      const today = new Date();
      return date < today;
    },



    // Delete Modal

    show_delete_modal(schedule_item) {
      this.delete_item = schedule_item;
      this.$bvModal.show("delete_modal");
    },

    // Delete the schedule item.
    delete_schedule_item() {
      this.$apollo
        .mutate({
          mutation: DELETE_HACKATHON_PROGRAM,
          variables: {
            id: this.delete_item.id,
          },
        })
        .then((res) => {
          this.$bvModal.hide("delete_modal");
          // Refetching the program data
          this.$apollo.queries.get_hackathon_program.refetch();
          // TODO: Push the user back to a particular page.
          // Perhaps the hackathon default page.
        })
        .catch((res) => {
          // TODO: Create notification that something went wrong.
        });
    },

    // CRU Modal
    show_cru_modal(schedule_item) {
      if (schedule_item) {
        // Edit Existing
        this.cru_modal.edit_item = schedule_item;
        this.cru_modal.form = {
          name: schedule_item.name,
          description: schedule_item.description,
          start_time: schedule_item.startTime.substring(
            0,
            16
          ),
          end_time: schedule_item.endTime.substring(0, 16),
        };

        this.cru_modal.mode = "edit";
        this.$bvModal.show("cru_modal");
      } else {
        // Create one
        this.cru_modal.edit_item = {};
        this.cru_modal.form = {
          name: null,
          description: "",
          start_time: null,
          end_time: null,
        };
        this.cru_modal.mode = "create";
        this.$bvModal.show("cru_modal");
      }
    },
    save_cru_modal() {
      this.$refs.form_submit_button.click();
    },
    submit_form() {
      if (this.cru_modal.mode == "create") {
        this.create_schedule_item();
      } else if (this.cru_modal.mode == "edit") {
        this.update_schedule_item();
      }
    },
    create_schedule_item() {
      this.cru_modal.loading = true;

      this.$apollo
        .mutate({
          mutation: CREATE_HACKATHON_PROGRAM,
          variables: {
            hackathon_id: this.hackathon_id,
            name: this.cru_modal.form.name,
            description: this.cru_modal.form.description,
            start_time: this.cru_modal.form.start_time,
            end_time: this.cru_modal.form.end_time,
          },
        })
        .then((res) => {
          this.cru_modal.loading = false;
          this.cru_modal.success = true;
          // Refetching the program data
          this.$apollo.queries.get_hackathon_program.refetch();
          setTimeout(() => {
            this.cru_modal.success = false;
            this.$bvModal.hide("cru_modal");
          }, 1000);
        })
        .catch((res) => {
          this.cru_modal.loading = false;
          // TODO: Create notification that something went wrong.
        });
    },
    update_schedule_item() {
      this.cru_modal.loading = true;

      this.$apollo
        .mutate({
          mutation: UPDATE_HACKATHON_PROGRAM,
          variables: {
            id: this.cru_modal.edit_item.id,
            name: this.cru_modal.form.name,
            description: this.cru_modal.form.description,
            start_time: this.cru_modal.form.start_time,
            end_time: this.cru_modal.form.end_time,
          },
        })
        .then((res) => {
          this.$apollo.queries.get_hackathon_program.refetch();
          this.cru_modal.loading = false;
          this.cru_modal.success = true;
          setTimeout(() => {
            this.cru_modal.success = false;
            this.$bvModal.hide("cru_modal");
          }, 1000);
        })
        .catch((res) => {
          this.cru_modal.loading = false;
          // TODO: Create notification that something went wrong.
        });
    },

    // Not working will implement if needed
    // duration_string(start_datetime, end_datetime) {
    //     let duration_ms = end_datetime - start_datetime;
    //     let days = Math.floor(duration_ms / (1000 * 60 * 60 * 24));
    //     let hours = Math.floor(
    //         (duration_ms % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    //     );
    //     let minutes = Math.floor(
    //         (duration_ms % (1000 * 60 * 60)) / (1000 * 60)
    //     );

    //     if (days > 0) {
    //         return `${days} Days`;
    //     }
    //     else if (hours> 0 ){
    //         if (minutes > 0){
    //             return `${hours} Hours`;
    //         }
    //         else{}

    //     }
    // },
  },

  mounted() {
    this.manage_get_hackathon_program();
  },
  watch: {
    hackathon_id() {
      this.manage_get_hackathon_program();
    },
  },
  created() {
    // setting this queries fetch policy
    graph_utils.set_fetch_cache_and_network(
      this.$apollo.queries.get_hackathon_program
    );
  },
};
</script>

<style>
.strikethrough {
  text-decoration: line-through;
}

/* .table-rows {
    border-bottom: red !important;
} */
</style>
